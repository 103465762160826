export function numberSeprator(number) {
  if (!isNaN(number)) {
    const f = new Intl.NumberFormat("en-us", {});
    return f.format(number);
  }
  return number;
}

export function priceAsDollar(number) {
  if (!isNaN(number)) {
    const f = new Intl.NumberFormat("en-us", {});
    return f.format(number);
  }
  return number;
}

export function priceAsToman(number) {
  console.log(number);
  if (!isNaN(number)) {
    // const f = new Intl.NumberFormat('en', {currency: 'IRR', style: 'currency'});
    const f = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return f.format(number);
  }
  return number;
}
