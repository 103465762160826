import { Box, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { theme } from "../../theme";
import { priceAsDollar, priceAsToman } from "../../utils/helpers";

const InvoiceDetail = ({ invoice }) => {
  return (
    <Fragment>
      <Box sx={{ textAlign: "center" }} my={2}>
        <img
          src={invoice.merchant_logo}
          alt={invoice.merchant_name}
          width={220}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="pairTextProp">{invoice.merchant_name}</Typography>
        <Typography className="dotted-text-filler" flex={1} mx={1} />
        <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
          نام پذیرنده
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="pairTextProp">{invoice.date}</Typography>
        <Typography className="dotted-text-filler" flex={1} mx={1} />
        <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
          زمان صدور
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="pairTextProp">{invoice.invoice_number}</Typography>
        <Typography className="dotted-text-filler" flex={1} mx={1} />
        <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
          شماره فاکتور
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="pairTextProp">{invoice.merchant_url}</Typography>
        <Typography className="dotted-text-filler" flex={1} mx={1} />
        <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
          آدرس پذیرنده
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="pairTextProp" sx={{ direction: "rtl" }}>
          {invoice.coin.currency === "IRT"
            ? priceAsToman(invoice.amount)
            : priceAsDollar(invoice.amount)}{" "}
          {invoice.coin.symbol}
        </Typography>
        <Typography className="dotted-text-filler" flex={1} mx={1} />
        <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
          مبلغ قابل پرداخت
        </Typography>
      </Stack>
    </Fragment>
  );
};

export default InvoiceDetail;
