import { Error } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { theme } from "../../theme";

const NotAllowedPayment = ({ message }) => {
  return (
    <Box flex={1} sx={{ padding: { xs: 2, md: 4, lg: 6 } }}>
      <Box sx={{ textAlign: "center" }}>
        <Error color="error" sx={{ fontSize: "72px" }} />
        <Typography variant="h6" sx={{ direction: 'rtl' }} color={theme.palette.grey[700]}>
        {message}
        </Typography>
      </Box>
    </Box>
  )
}

export default NotAllowedPayment