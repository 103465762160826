import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AuthContext from "../../store/authContext";
import PayResultContext from "../../store/payResultContext";
import { theme } from "../../theme";
import { priceAsDollar, priceAsToman } from "../../utils/helpers";

const InvoicePreCheck = (props) => {
  const trackid = sessionStorage.getItem("trackid");

  const [cookie, setCookie] = useCookies(["token"]);

  const authContext = useContext(AuthContext);
  const payResultContext = useContext(PayResultContext);

  const [isLoading, setIsLoading] = useState();
  const [isFinalPayLoading, setIsFinalPayLoading] = useState(false);
  const [hasBalance, setHasBalance] = useState();
  const [invoiceData, setInvoiceData] = useState();

  const getInvoicePreCheck = async () => {
    await axios
      .post("/xuser/pay-invoice-pre-check",
        {
          trackid: trackid,
          currency: props.selectedWallet,
        },
        {
          headers: {
            Authorization: `Bearer ${cookie.token}`
          },
        }
      )
      .then((response) => {
        setInvoiceData(response.data);
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        props.onError(errors[Object.keys(errors)[0]]);
      });
  };

  const handleFinalPay = async () => {
    setIsFinalPayLoading(true);
    await axios
      .post("/xuser/pay-invoice",
        {
          trackid: trackid,
          currency: props.selectedWallet,
        },
        {
          headers: {
            Authorization: `Bearer ${cookie.token}`
          },
        }
      )
      .then((response) => {
        const r = response.data.is_paid_result;
        const c = response.data.callback;
        const d = response.data.item;
        payResultContext.setPaid(r, c, d);
        authContext.updateSatat();
        setIsFinalPayLoading(false);
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        props.onError(errors[Object.keys(errors)[0]]);
        setIsFinalPayLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    setHasBalance(null);
    getInvoicePreCheck();
  }, [props.selectedWallet]);

  useEffect(() => {
    if (invoiceData) {
      setHasBalance(invoiceData.details.can_pay);
      setIsLoading(false);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (hasBalance !== null) {
      props.onCheckUserBalance(hasBalance);
    }
  }, [hasBalance]);

  return (
    <Fragment>
      {isLoading ? (
        <Box sx={{ textAlign: "center", mt: 16 }}>
          <CircularProgress />
        </Box>
      ) : (
        invoiceData && (
          <Box>
            <Stack direction="row" justifyContent="space-between" my={3}>
              <Typography variant="pairTextProp" sx={{ direction: "rtl" }}>
                {invoiceData.item.coin.currency === "IRT"
                  ? priceAsToman(invoiceData.item.amount)
                  : priceAsDollar(invoiceData.item.amount)}{" "}
                {invoiceData.item.coin.symbol}
              </Typography>
              <Typography className="dotted-text-filler" flex={1} mx={1} />
              <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
                مبلغ فاکتور
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" my={3}>
              <Typography variant="pairTextProp">
                {invoiceData.details.rate}
              </Typography>
              <Typography className="dotted-text-filler" flex={1} mx={1} />
              <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
                نرخ تبدیل
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" my={3}>
              <Typography variant="pairTextProp" sx={{ direction: "rtl" }}>
                {invoiceData.details.coin.currency === "IRT"
                  ? priceAsToman(invoiceData.details.get_amount)
                  : priceAsDollar(invoiceData.details.get_amount)}{" "}
                {invoiceData.details.coin.symbol}
              </Typography>
              <Typography className="dotted-text-filler" flex={1} mx={1} />
              <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
                مبلغ قابل پرداخت
              </Typography>
            </Stack>
            <Box mt={4}>
              <LoadingButton
                loading={isFinalPayLoading}
                size="large"
                fullWidth={true}
                variant="contained"
                disabled={!hasBalance}
                sx={{ paddingY: 2 }}
                onClick={handleFinalPay}
              >
                <Typography variant="h6">پرداخت</Typography>
              </LoadingButton>
            </Box>
          </Box>
        )
      )}
    </Fragment>
  );
};

export default InvoicePreCheck;
