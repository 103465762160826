import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/authContext";
import Login from "../auth/Login";
import TwoFA from "../auth/TwoFA";
import Balance from "../balance/Balance";
import Invoice from "../Invoice/Invoice";
import PayResult from "./PayResult";
import { theme } from "../../theme";
import PayResultContext from "../../store/payResultContext";
import { useCookies } from "react-cookie";
import axios from "axios";

const Merchant = () => {
  const authContext = useContext(AuthContext);
  const payResultContext = useContext(PayResultContext);

  const [cookie, setCookie] = useCookies(["token"]);

  const mobileView = useMediaQuery(theme.breakpoints.down("md"));

  const [isAuthorized, setIsAuthorized] = useState();
  const [counter, setCounter] = useState();

  const getUserDetail = async () => {
    await axios
      .get("/xuser/details", {
        headers: {
          Authorization: `Bearer ${cookie.token}`,
        },
      })
      .then((response) => {
        setIsAuthorized(true);
        authContext.skipState("balance");
      })
      .catch((error) => {
        setIsAuthorized(false);
        authContext.skipState("login");
      });
  };

  useEffect(() => {
    if (cookie.token) {
      getUserDetail();
    } else {
      setIsAuthorized(false);
      authContext.skipState("login");
    }
  }, [cookie]);

  useEffect(() => {
    if (authContext.state === "payResult") {
      if (counter === 0) {
        window.location.replace(payResultContext.callbackURL);
      } else {
        const t = setInterval(() => {
          setCounter(counter - 1);
        }, 1000);
        return () => clearInterval(t);
      }
    }
  }, [counter]);

  useEffect(() => {
    if (authContext.state === "payResult") {
      setCounter(5);
    }
  }, [authContext.state]);

  useEffect(() => {
    if (mobileView) {
      authContext.skipState("invoice");
    } else {
      authContext.skipState("login");
    }
  }, [mobileView]);

  return (
    <Box sx={{ marginY: { xs: 2, md: 4, lg: 6 } }}>
      {isAuthorized !== undefined ? (
        <Paper elevation={0} sx={{ borderRadius: "24px", minHeight: "480px" }}>
          {mobileView ? (
            <Stack direction="column" justifyContent="space-between">
              {authContext.state === "invoice" ? (
                <Invoice />
              ) : authContext.state === "login" ? (
                <Login />
              ) : authContext.state === "verifyCode" ? (
                <TwoFA />
              ) : authContext.state === "balance" ? (
                <Fragment>
                  <Balance />
                  <Divider orientation="horizontal" flexItem />
                  <Invoice />
                </Fragment>
              ) : authContext.state === "payResult" ? (
                <Fragment>
                  <PayResult />
                  <Divider orientation="horizontal" flexItem />
                  <Invoice />
                </Fragment>
              ) : null}
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="space-between">
              <Invoice />
              <Divider
                orientation="vertical"
                flexItem
                sx={{ display: { xs: "none", md: "block" } }}
              />
              {authContext.state === "login" ? (
                <Login />
              ) : authContext.state === "verifyCode" ? (
                <TwoFA />
              ) : authContext.state === "balance" ? (
                <Balance />
              ) : authContext.state === "payResult" ? (
                <PayResult />
              ) : null}
            </Stack>
          )}

          {authContext.state === "payResult" ? (
            <Box sx={{ textAlign: "center", paddingY: 4 }}>
              <Typography variant="body1" color={theme.palette.grey[600]}>
                بازگشت به سایت پذیرنده تا {counter} ثانیه دیگر
              </Typography>
            </Box>
          ) : null}
        </Paper>
      ) : (
        <Box sx={{ textAlign: "center", mt: 16 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Merchant;
