import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/authContext";
import InvoiceDetail from "./InvoiceDetail";

const Invoice = () => {

  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState();
  const trackid = new URLSearchParams(window.location.search).get("trackid");
  const [invalidTrackID, setInvalidTrackID] = useState(false);
  const [showError, setShowError] = useState({
    show: false,
    message: "Error",
  });

  const handleClose = () => {
    setShowError({
      show: false,
      message: "",
    });
  };

  const getInvoice = async () => {
    await axios
      .post("/xuser/invoice", {
        trackid: trackid,
      })
      .then((response) => {
        setInvoice(response.data.item);
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        setShowError({
          show: true,
          message: errors[Object.keys(errors)[0]],
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (trackid) {
      sessionStorage.setItem("trackid", trackid);
      setIsLoading(true);
      getInvoice();
    } else {
      setInvalidTrackID(true);
    }
  }, []);

  useEffect(() => {
    if (invoice) {
      setIsLoading(false);
    }
  }, [invoice]);

  if (invalidTrackID) {
    return (
      <Box
        flex={1}
        sx={{ padding: { xs: 2, md: 4, lg: 6 }, textAlign: "center" }}
      >
        <Typography pt={8} variant="h4">
          Invalid Track ID!
        </Typography>
      </Box>
    );
  }

  return (
    <Box flex={1} sx={{ padding: { xs: 2, md: 4, lg: 6 } }}>
      {isLoading ? (
        <Box sx={{ textAlign: "center", mt: 16 }}>
          <CircularProgress />
        </Box>
      ) : (
        invoice && (
          <Fragment>
            <InvoiceDetail invoice={invoice} />
            <Box mt={4}>
              <Button
                size="large"
                fullWidth={true}
                variant="contained"
                type="button"
                sx={{ paddingY: 2, display: { xs: authContext.state === 'invoice' ? "block" : "none", md: "none" } }}
                onClick={() => {
                  authContext.updateSatat();
                }}
              >
                <Typography variant="h6">پرداخت</Typography>
              </Button>
            </Box>
          </Fragment>
        )
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showError.show}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {showError.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Invoice;
