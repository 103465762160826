import { CheckCircle, Error } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { Fragment, useContext } from "react";
import PayResultContext from "../../store/payResultContext";
import { theme } from "../../theme";
import { priceAsDollar, priceAsToman } from "../../utils/helpers";

const PayResult = () => {

  const payResultContext = useContext(PayResultContext);

  return (
    <Box flex={1} sx={{ padding: { xs: 2, md: 4, lg: 6 } }}>
      <Box sx={{ textAlign: "center" }}>
        {payResultContext.paidResult ? (
          <CheckCircle color="success" sx={{ fontSize: "72px" }} />
        ) : (
          <Error color="error" sx={{ fontSize: "72px" }} />
        )}
        <Typography variant="h6" color={theme.palette.grey[700]}>
          {payResultContext.paidResult
            ? "پرداخت شما با موفقیت انجام شد"
            : "خطا در پرداخت"}
        </Typography>
      </Box>

      {payResultContext.paidResult && (
        <Fragment>
          <Stack direction="row" justifyContent="space-between" my={3}>
            <Typography variant="pairTextProp">
              {payResultContext.paidData.merchant_name}
            </Typography>
            <Typography className="dotted-text-filler" flex={1} mx={1} />
            <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
              نام پذیرنده
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" my={3}>
            <Typography variant="pairTextProp" sx={{ direction: "rtl" }}>
              {payResultContext.paidData.coin.currency === "IRT"
                ? priceAsToman(payResultContext.paidData.amount)
                : priceAsDollar(payResultContext.paidData.amount)}{" "}
              {payResultContext.paidData.coin.symbol}
            </Typography>
            <Typography className="dotted-text-filler" flex={1} mx={1} />
            <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
              مقدار
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" my={3}>
            <Typography variant="pairTextProp">
              {payResultContext.paidData.pay_date}
            </Typography>
            <Typography className="dotted-text-filler" flex={1} mx={1} />
            <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
              زمان پرداخت
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" my={3}>
            <Typography variant="pairTextProp">{payResultContext.paidData.rate}</Typography>
            <Typography className="dotted-text-filler" flex={1} mx={1} />
            <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
              نرخ تبدیل
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" my={3}>
            {/* <Typography variant="pairTextProp">{payResultContext.paidData.paid_amount}</Typography> */}
            <Typography variant="pairTextProp" sx={{ direction: "rtl" }}>
              {payResultContext.paidData.coin.currency === "IRT"
                ? priceAsToman(payResultContext.paidData.paid_amount)
                : priceAsDollar(payResultContext.paidData.paid_amount)}{" "}
              {payResultContext.paidData.payer_coin.symbol}
            </Typography>
            <Typography className="dotted-text-filler" flex={1} mx={1} />
            <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
              مبلغ پرداختی
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" my={3}>
            <Typography variant="pairTextProp">
              {payResultContext.paidData.invoice_transaction_id}
            </Typography>
            <Typography className="dotted-text-filler" flex={1} mx={1} />
            <Typography variant="pairTextVal" color={theme.palette.grey[600]}>
              کد تراکنش
            </Typography>
          </Stack>
        </Fragment>
      )}
    </Box>
  );
};
export default PayResult;
