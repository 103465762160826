import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      dark: '#0e172a',
      main: '#14213D',
      light: '#434d63'
    },
    error: {
      light: '#ed4b82',
      main: '#e91e63',
      dark: '#a31545'
    },
    success: {
      light: '#35b77b',
      main: '#03a55a',
      dark: '#02733e'
    },
    background: {
      default: "#e5e5e5",
    },
  },
  typography: {
    pairTextProp:{
        fontSize: 18,
        fontWeight: "bold",
    },
    pairTextVal: {
        fontSize: 18,
        fontWeight: "normal",
    },
    fontFamily: ["Vazirmatn", "sans-serif"].join(","),
  },
});
