import { createContext, useState } from "react";

const PayResultContext = createContext({
    paidResult: false,
    callbackURL: '',
    paidData: null,
    setPaid: function(result, callbackURL, data) {}
});

export function PayResultContextProvider(props){
    const [activePaidResult, setActivePaidResult] = useState(false);
    const [activeCallbackURL, setActiveCallbackURL] = useState();
    const [activePaidData, setActivePaidData] = useState();

    const setPaidHandler = (result, callbackURL, data) => {
        setActivePaidResult(result);
        setActiveCallbackURL(callbackURL);
        setActivePaidData(data);
    }

    const context = {
        paidResult: activePaidResult,
        callbackURL: activeCallbackURL,
        paidData: activePaidData,
        setPaid: setPaidHandler
    }

    return(
        <PayResultContext.Provider value={context}>
            {props.children}
        </PayResultContext.Provider>
    )
}

export default PayResultContext;