import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import AuthContext from "../../store/authContext";
import { theme } from "../../theme";

const TwoFA = () => {
  const authContext = useContext(AuthContext);

  const [cookie, setCookie] = useCookies(["token"]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSendingCodeAgainLoading, setIsSendingCodeAgainLoading] =
    useState(false);
  const [verifyCode6, setVerifyCode6] = useState();
  const [showError, setShowError] = useState({
    show: false,
    message: "Error",
  });

  const verifyCode = async () => {
    await axios
      .post("/auth/invoice-email-twofa",
        {
          uuid: sessionStorage.getItem("uuid"),
          code: verifyCode6,
        }
      ).then(function (response) {
        const userToken = response.data.token;

        // set token in cookie
        setCookie("token", userToken, {
          maxAge: 60 * 60 * 24,
          path: "/",
        });

        authContext.updateSatat();
      }).catch(function (error) {
        const errors = error.response.data.errors;
        setShowError({
          show: true,
          message: errors[Object.keys(errors)[0]],
        });
      });

    setIsLoading(false);
  };

  const sendingCodeAgain = async () => {
    setIsSendingCodeAgainLoading(true);
    await axios
      .post("/auth/resend-invoice-email-twofa",
        {
          uuid: sessionStorage.getItem("uuid")
        }
      ).then(function (response) {
        sessionStorage.setItem("uuid", response.data.uuid);

      }).catch(function (error) {
        const errors = error.response.data.errors;
        setShowError({
          show: true,
          message: errors[Object.keys(errors)[0]],
        });
      });
      setIsSendingCodeAgainLoading(false);

  };

  const handleClose = () => {
    setShowError({
      show: false,
      message: "",
    });
  };

  const codeChangeHandler = (e) => {
    const regex = /^[0-9\b]+$/;

    const t = e.target.value;

    if (!regex.test(t)) {
      e.target.value = t.substring(0, t.length - 1);
      return;
    }

    if (t === "" || regex.test(t)) {
      setVerifyCode6(t);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (verifyCode6 > 999999 || verifyCode6 < 100000) {
      setShowError({
        show: true,
        message: "Verification code must be 6 digit numbers!",
      });
      return;
    }

    setIsLoading(true);
    verifyCode();
  };
  return (
    <Box flex={1} sx={{ padding: { xs: 2, md: 4, lg: 6 } }}>
      <Box sx={{ textAlign: "center" }}>
        <img src="./images/zipay.svg" alt="wallet" width={180} />
      </Box>
      <Box sx={{ textAlign: "center", my: { xs: 1, md: 2 } }}>
        <Typography variant="body1">
          لطفا کد ارسال شده به ایمیل خود را وارد کنید
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          fullWidth={true}
          placeholder="کد ۶ رقمی"
          required
          autoFocus
          variant="outlined"
          type="text"
          onChange={codeChangeHandler}
          inputProps={{ maxLength: 6 }}
          sx={{
            "& .MuiInputBase-root": {
              "& input": {
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 24,
              },
            },
          }}
        />
        <Box mt={2}>
          <LoadingButton
            loading={isLoading}
            size="large"
            fullWidth={true}
            variant="contained"
            type="submit"
            sx={{ paddingY: 2 }}
          >
            <Typography variant="h6">ورود</Typography>
          </LoadingButton>
        </Box>
      </Box>
      <Typography
        mt={2}
        sx={{ textAlign: "center" }}
        variant="body1"
        color={theme.palette.grey[600]}
      >
        <LoadingButton
          loading={isSendingCodeAgainLoading}
          size="small"
          variant="text"
        >
          <Typography variant="body1">ارسال مجدد</Typography>
        </LoadingButton>
        کدی دریافت نکرده‌اید؟
      </Typography>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showError.show}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {showError.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TwoFA;
