import { Container, Paper } from "@mui/material";
import Merchant from "./components/merchant/Merchant";

const App = () => {
  return (
    <Container>
      <Merchant />
    </Container>
  );
};

export default App;
