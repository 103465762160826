import { createContext, useState } from "react";

const AuthContext = createContext({
    state: 'login', // invoice, login, verifyCode, balance, payResult
    updateSatat: function() {},
    skipState: function(state) {}
});

export function AuthContextProvider(props){
    const [activeState, setActiveState] = useState('login');

    const updateStateHandler = () => {
        if(activeState === 'invoice'){
            setActiveState('login')
        }else if(activeState === 'login'){
            setActiveState('verifyCode')
        }else if(activeState === 'verifyCode'){
            setActiveState('balance')
        }else if(activeState === 'balance'){
            setActiveState('payResult')
        }
    }

    const skipStateHandler = (state) => {
        setActiveState(state);
    }

    const context = {
        state: activeState, 
        updateSatat: updateStateHandler,
        skipState: skipStateHandler,
    }

    return(
        <AuthContext.Provider value={context}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;