import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./main.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { AuthContextProvider } from "./store/authContext";
import { PayResultContextProvider } from "./store/payResultContext";
import axios from "axios";

// axios.defaults.baseURL = "https://sandbox.zipay.io/";
axios.defaults.baseURL = 'https://dev.zipay.io/';
axios.defaults.headers.common["Accept-Language"] = "fa-IR";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider>
        <PayResultContextProvider>
          <App />
        </PayResultContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
