import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import AuthContext from "../../store/authContext";
import { theme } from "../../theme";

const Login = () => {

  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState({
    show: false,
    message: "Error"
  });

  const emailInputRef = useRef();
  const passworInputRef = useRef();

  const handleClose = () => {
    setShowError({
      show: false,
      message: ""
    })
  };

  const login = async (email, password) => {
    await axios
      .post("/auth/invoice-login", {
        email: email,
        password: password,
      }
    )
      .then(function (response) {
        sessionStorage.setItem("uuid", response.data.uuid);
        authContext.updateSatat();
      })
      .catch(function (error) {
        const errors = error.response.data.errors;
        setShowError({
          show: true,
          message: errors[Object.keys(errors)[0]]
        })
        // console.log(errors[Object.keys(errors)[0]]);
        // console.log(errors["password"])
        // for (const key in Object.keys(errors)) {
        //   console.log(errors[key])
        // }
      });

    setIsLoading(false);
    // document.getElementById("login-form").reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = emailInputRef.current.value;
    const password = passworInputRef.current.value;

    setIsLoading(true);
    login(email, password);
  };
  return (
    <Box flex={1} sx={{ padding: { xs: 2, md: 4, lg: 6 } }}>
      <Box sx={{ textAlign: "center" }}>
        <img src="./images/zipay.svg" alt="wallet" width={180} />
      </Box>
      <Box sx={{ textAlign: "center", my: { xs: 1, md: 2 } }}>
        <Typography variant="body1">
          جهت پرداخت ابتدا وارد حساب کاربری خود شوید
        </Typography>
      </Box>

      <Box component="form" id="login-form" onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          fullWidth={true}
          placeholder="پست الکترونیکی"
          type="email"
          required
          autoComplete="email"
          autoFocus
          variant="outlined"
          inputRef={emailInputRef}
          sx={{
            "& .MuiInputBase-root": {
              "& input": {
                textAlign: "right",
              },
            },
          }}
        />
        <TextField
          fullWidth={true}
          variant="outlined"
          type="password"
          required
          placeholder="کلمه عبور"
          inputRef={passworInputRef}
          sx={{
            "& .MuiInputBase-root": {
              "& input": {
                textAlign: "right",
              },
            },
          }}
        />
        <Box mt={4}>
          <LoadingButton
            loading={isLoading}
            size="large"
            fullWidth={true}
            variant="contained"
            type="submit"
            sx={{ paddingY: 2 }}
          >
            <Typography variant="h6">ورود</Typography>
          </LoadingButton>
        </Box>
      </Box>
      <Typography
        mt={2}
        sx={{ textAlign: "center" }}
        variant="body1"
        color={theme.palette.grey[600]}
      >
        حساب کاربری ندارید؟
        <Typography
          display="inline"
          variant="body1"
          sx={{ fontWeight: "bold" }}
          color={theme.palette.grey[600]}
        >
          <a href=""> عضو شوید</a>
        </Typography>
      </Typography>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showError.show}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {showError.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
