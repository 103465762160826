import {
  alpha,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { priceAsDollar, priceAsToman } from "../../utils/helpers";
import { theme } from "../../theme";

const Wallet = (props) => {
  const [cookie, setCookie] = useCookies(["token"]);

  const [isLoading, setIsLoading] = useState(false);
  const [wallets, setWallets] = useState();

  const getBalance = async () => {
    await axios
      .get("/xuser/balance", {
        headers: {
          Authorization: `Bearer ${cookie.token}`,
        },
      })
      .then((response) => {
        setWallets(response.data.items);
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        props.onError(errors[Object.keys(errors)[0]]);
      });
  };

  const walletChnageHandler = (e) => {
    const walletType = e.target.value;
    props.onWalletChanged(walletType);
  };

  useEffect(() => {
    setIsLoading(true);
    getBalance();
  }, []);

  useEffect(() => {
    if (wallets) {
      setIsLoading(false);
    }
  }, [wallets]);

  return (
    <Fragment>
      {isLoading ? (
        <Box sx={{ textAlign: "center", mt: 16 }}>
          <CircularProgress />
        </Box>
      ) : (
        wallets && (
          <Fragment>
            <Box sx={{ textAlign: "center", my: { xs: 1, md: 4 } }}>
              <Typography variant="body1">
                کیف پول موردنظر خود را برای پرداخت انتخاب کنید
              </Typography>
            </Box>
            <FormControl
              fullWidth
              error={props.enoughValue !== undefined && !props.enoughValue}
            >
              <InputLabel id="wallet-select-label">کیف پول</InputLabel>
              <Select
                labelId="wallet-select-label"
                id="wallet-select"
                label="کیف پول"
                sx={{ 
                  borderRadius: (props.enoughValue !== undefined && !props.enoughValue) ? "8px 8px 0 0" : "8px",
                  
                }}
                onChange={walletChnageHandler}
              >
                {wallets?.map((item, index) => {
                  return (
                    <MenuItem value={item.coin.currency} key={index}>
                      <Grid container>
                        <ListItemText>
                          <Box
                            component="span"
                            sx={{
                              color: theme.palette.grey[600],
                              paddingRight: "4px",
                              width: "48px",
                              textAlign: "right",
                              display: "inline-block",
                            }}
                          >
                            {item.coin.symbol}
                          </Box>
                          {item.coin.currency === "IRT"
                            ? priceAsToman(item.balance)
                            : priceAsDollar(item.balance)}
                        </ListItemText>
                        <Typography>کیف پول {item.coin.symbol}</Typography>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </Select>
              {props.enoughValue !== undefined && !props.enoughValue ? (
                <FormHelperText
                  sx={{
                    margin: 0,
                  }}
                >
                  <Typography
                    textAlign="right"
                    sx={{
                      bgcolor: alpha(theme.palette.error.main, 0.2),
                      color: theme.palette.error.main,
                      padding: 1,
                      margin: 0,
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    موجودی کافی نیست
                  </Typography>
                </FormHelperText>
              ) : null}
            </FormControl>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default Wallet;
