import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import InvoicePreCheck from "./InvoicePreCheck";
import NotAllowedPayment from "./NotAllowedPayment";
import Wallet from "./Wallet";

const Balance = () => {
  // const trackId = sessionStorage.getItem("trackid");
  // const [trackId, setTrackId] = useState(sessionStorage.getItem("trackid"));

  const [cookie, setCookie] = useCookies(["token"]);

  const [userAllowedToPay, setUserAllowedToPay] = useState();
  const [notAllowedMessage, setNotAllowedMessage] = useState("");
  const [selectedWallet, setSelectedWallet] = useState();
  const [enoughValue, setEnoughValue] = useState();
  const [showError, setShowError] = useState({
    show: false,
    message: "Error",
  });

  const handleClose = () => {
    setShowError({
      show: false,
      message: "",
    });
  };

  const handleChildError = (message) => {
    setShowError({
      show: true,
      message: message,
    });
  };

  const handleCheckUserBalance = (balance) => {
    setEnoughValue(balance);
  };

  const walletChanged = (wallet) => {
    setSelectedWallet(wallet);
  };

  const checkUserAllowed = async (trackId) => {
    await axios
      .post(
        "/xuser/allow-to-pay-invoice",
        {
          trackid: trackId,
        },
        {
          headers: {
            Authorization: `Bearer ${cookie.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.allow) {
          setUserAllowedToPay(true);
        } else {
          setNotAllowedMessage(response.data.message);
          setUserAllowedToPay(false);
        }
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        if (errors.trackid) {
          setNotAllowedMessage(errors.trackid);
        } else {
          setNotAllowedMessage("Unexpectable Error");
        }
        setUserAllowedToPay(false);
      });
  };

  useEffect(() => {
    console.log(sessionStorage.getItem("trackid"));
    checkUserAllowed(sessionStorage.getItem("trackid"));
  }, [sessionStorage.getItem("trackid")]);

  return (
    <Box flex={1} sx={{ padding: { xs: 2, md: 4, lg: 6 } }}>
      {userAllowedToPay !== undefined ? (
        userAllowedToPay ? (
          <Fragment>
            <Wallet
              onWalletChanged={walletChanged}
              onError={handleChildError}
              enoughValue={enoughValue}
            />

            {selectedWallet && (
              <InvoicePreCheck
                onError={handleChildError}
                selectedWallet={selectedWallet}
                onCheckUserBalance={handleCheckUserBalance}
              />
            )}
          </Fragment>
        ) : (
          <NotAllowedPayment message={notAllowedMessage} />
        )
      ) : (
        <Box sx={{ textAlign: "center", mt: 16 }}>
          <CircularProgress />
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showError.show}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {showError.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Balance;
